<template>
  <div>
    <div>
      <div id="viewer" />
    </div>
  </div>
</template>
<script>
  import StimulsoftViewer from 'stimulsoft-reports-js/Scripts/stimulsoft.viewer';
  import Stimulsoft from 'stimulsoft-reports-js/Scripts/stimulsoft.reports';
  import { RelatorioService } from '@common/services';
  export default {
     props: {
       parametro: { 
          type: String,
          default: null 
       },
       parametroUrl: { 
          type: String,
          default: null 
       }
     },
     data() {
       return {
          viewer: null,
          dataSource: null,
          requisicao: null,
          parametroRecebido: null,
          ehRelatorioPorCodigo: false,
       };
     },
     watch: {
      dataSource() {
        this.$emit("obterDataSource", this.dataSource)
      }
    },
     created() {
      Stimulsoft.Stimulsoft.Base.StiLicense.Key = `${process.env.VUE_APP_STIMULSOFT_LICENSE_KEY}`;
     },
     methods: {
      obterDataSourceInicial: function(relatorioId, codigoRelatorio, sistema) {
        RelatorioService.buscar(relatorioId, codigoRelatorio, sistema)
            .then((res) => {
               this.endpoint = res.data.endpoint;
               this.requisicao = this.montarRequisicao();

               if (!this.requisicao.url.includes(`{${this.parametroUrl}}`)) {
                  RelatorioService.buscarDatasource(
                    this.endpoint.api,
                    this.endpoint.verboHttp,
                    this.requisicao
                  )
                  .then((res) => {
                    this.dataSource = res.data;
                  })
                  .catch(() => {
                    this.toastErro(
                      this.$t('componentes.gerador_relatorio.erro_ao_buscar_datasource')
                    );
                  })
                  .finally(() => {
                    this.$store.dispatch('Layout/terminarCarregamento');
                  });
               } 
            })

      },
      inicializarVisualizador: function (relatorioId, parametros = null) {
        if (parametros) {
          this.parametros = parametros[0];
        }

        this.buscarRelatorio(relatorioId);
      },
      inicializarVisualizadorPorCodigo: function (
        codigoRelatorio,
        parametros = [],
        parametro,
        sistema
      ) {
        this.ehRelatorioPorCodigo = true;
        this.parametroRecebido = parametro;
        this.parametros = parametros[0];
        this.buscarRelatorio(null, codigoRelatorio, sistema);
      },
       buscarRelatorio: function (relatorioId, codigoRelatorio, sistema) {
          this.$store.dispatch('Layout/iniciarCarregamento');
          RelatorioService.buscar(relatorioId, codigoRelatorio, sistema)
            .then((res) => {
               this.endpoint = res.data.endpoint;
               if (!res.data.arquivoRelatorio) {
                 this.toastErro(
                    this.$t(
                      'componentes.gerador_relatorio.arquivo_relatorio_nao_encontrado'
                    )
                 );
                 return;
               }

               let arquivo = Buffer.from(
                 res.data.arquivoRelatorio,
                 'base64'
               ).toString();
        
               this.modelo = arquivo;
               this.requisicao = this.montarRequisicao();

               if (!this.ehRelatorioPorCodigo) {
                this.parametroRecebido = this.parametro
               }

               if (this.requisicao.url.includes(`{${this.parametroUrl}}`)) {
                  let url = this.requisicao.url.replace(`{${this.parametroUrl}}`, this.parametroRecebido);
                  this.requisicao.url = url
                  this.buscarDatasource(this.requisicao);
               } else {
                  this.buscarDatasource(this.requisicao);
               }
            })
            .catch((e) => {
               console.error(e.message);
               this.toastErro(this.$t('modulos.modelo_relatorio.erros.id_invalido'));
            })
            .finally(() => {
               this.$store.dispatch('Layout/terminarCarregamento');
            });
       },
       montarRequisicao: function () {
          return {
            url: this.endpoint.url,
            form: {}
          };
       },
       buscarDatasource: function (requisicao) {
          this.$store.dispatch('Layout/iniciarCarregamento');
          RelatorioService.buscarDatasource(
            this.endpoint.api,
            this.endpoint.verboHttp,
            requisicao
          )
            .then((res) => {
              this.dataSource = res.data;
              this.montarComponente(this.modelo);
            })
            .catch(() => {
               this.toastErro(
                 this.$t('componentes.gerador_relatorio.erro_ao_buscar_datasource')
               );
            })
            .finally(() => {
               this.$store.dispatch('Layout/terminarCarregamento');
            });
       },
       carregarRelatorio: function (arquivoJson) {
          let report = new Stimulsoft.Stimulsoft.Report.StiReport();

          if (arquivoJson) {
            let ehStringJson = this.ehStringJson(arquivoJson);
            let arq = arquivoJson;
            if (ehStringJson) arq = JSON.parse(arquivoJson);
            report.load(arq);
          }

          let dataSet = new Stimulsoft.Stimulsoft.System.Data.DataSet('KeL');
          dataSet.readJson(this.dataSource);
          report.regData(dataSet.dataSetName, '', dataSet);
          report.dictionary.synchronize();

          return report;
        },
        obterVariaveisRelatorio: function (arquivoJson) {
          let report = this.carregarRelatorio(arquivoJson);

          return report;
        },
        montarComponente: function (arquivoJson, marcaDagua) {
          let report = this.carregarRelatorio(arquivoJson);
          let options = new StimulsoftViewer.StiViewerOptions();
          options.toolbar.visible = true;
          options.toolbar.showSaveButton = false;
          options.toolbar.showOpenButton = false;
          options.toolbar.showAboutButton = false;
          options.toolbar.showFullScreenButton = false;
          options.appearance.fullScreenMode = false;
          this.viewer = new Stimulsoft.StiViewer(options, 'StiViewer', false);
          this.viewer.report = report;
          if (marcaDagua) {
            report.pages.list.forEach((pagina) => {
              pagina.watermark.enabled = true;
              pagina.watermark.angle = 0;
              pagina.watermark.font.size = 50;
              pagina.watermark.text = marcaDagua;
            });
          }

          if (this.parametros) {
            if (this.parametros.tipoPassagemParametro !== 'Url') {
              report.dictionary.variables.list.find(
                (objeto) => objeto.alias === 'parametro'
              ).val = this.parametro;
            }
          }

          if (this.ehRelatorioPorCodigo) {
            report.printToPdf();
          } else { 
            this.viewer.renderHtml('viewer');
          }
        },
       ehStringJson(str) {
          try {
            var json = JSON.parse(str);
            return typeof json === 'string';
          } catch (e) {
            return false;
          }
       },
     },
  };
  </script>
  