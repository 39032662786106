<template>
  <div>
    <modal-padrao
      ref="modal"
      :titulo="tituloModal"
      :max-width="maxWidth"
      :mostra-botao-salvar="!relatorioGerado"
      @ok="gerarRelatorio"
    >
      <parametros-relatorio-sql
        v-model="optionSelecionado"
        :exibir="exibirParametros"
        :tipo-input="tipoInput"
        :option-parametros="optionParametros"
        :parametros="endpointsParametros"
        :consulta="nome"
      />

      <GeradorRelatorioSql
        v-show="exibirRelatorio"
        :key="'gerador-relatorio-' + relatorioId"
        ref="relatorio" 
        :parametro="optionSelecionado"
        :parametro-url="campoParametro"
        @obterDataSource="obterDataSource"
      />
    </modal-padrao>
    
    <GeradorRelatorioSql
      v-if="relatorioCodigo"
      :key="'gerador-relatorio-' + relatorioId"
      ref="relatorio" 
      :parametro="optionSelecionado"
      :parametro-url="campoParametro"
      @obterDataSource="obterDataSource"
    />
  </div>
</template>

<script>
import ParametrosRelatorioSql from './ParametrosRelatorioSql.vue';
import GeradorRelatorioSql from '@components/misc/GeradorRelatorioSql.vue';
import { RelatorioService } from '@common/services';

export default {
  components: {
    GeradorRelatorioSql,
    ParametrosRelatorioSql
},
  data() {
    return {
      relatorioId: null,
      relatorioCodigo: null,
      sistema: null,
      maxWidth: '500px',
      optionSelecionado: null,
      json: null,
      variaveisRelatorio: null,
      dataSource: null,
      optionParametros: [],
      relatorioPossuiParametros: false,
      tipoInput: null,
      campoParametro: null,
      tituloModal: this.$t('modulos.modelo_relatorio.filtro.titulo_modal_parametros_relatorio'),
      exibirRelatorio: false,
      exibirParametros: false,
      prontoPraGerarRelatorio: true,
      relatorioGerado: false,
      endpointsParametros: [],
      nome: null
    };
  },
  watch: {
    dataSource() {
      this.preencherOptions()
    }
  },
  methods: {
    obterDataSource: function(valor) {
      this.dataSource = valor
    },
    preencherOptions() {
      if (this.tipoInput === 'select') {
          const campoRelatorio = Object.keys(this.dataSource)[0];
          if (this.dataSource && this.dataSource[campoRelatorio]) {
            const dadosRelatorio = this.dataSource[campoRelatorio];
            const parametroSet = new Set();

            dadosRelatorio.forEach(dado => {
              parametroSet.add(dado[this.campoParametro]);
            });

            this.optionParametros = Array.from(parametroSet);
          } else {
            this.optionParametros = [];
          }
        } else {
            this.optionParametros = [];
        }
    },
    obterVariaveisRelatorio() {
      this.variaveisRelatorio = this.$refs.relatorio.obterVariaveisRelatorio(this.json);
      this.relatorioPossuiParametros = this.variaveisRelatorio.dictionary.variables.list.length > 0;
      if (this.relatorioPossuiParametros) {
        this.nome = this.variaveisRelatorio.dictionary.variables.list.find((objeto) => objeto.alias === 'nome').val;
        this.tipoInput = this.variaveisRelatorio.dictionary.variables.list.find((objeto) => objeto.alias === 'tipoInput').val;
        this.campoParametro = this.variaveisRelatorio.dictionary.variables.list.find((objeto) => objeto.alias === 'campoParametro').val;

        const endpointsParametros = this.variaveisRelatorio.dictionary.variables.list.reduce((acc, objeto) => {
          switch (objeto.alias) {
            case 'id':
              acc.id = this.gerarGuid();
              break;
            case 'nome':
              acc.nome = objeto.val;
              break;
            case 'obrigatorio':
              acc.obrigatorio = objeto.val === 'true';
              break;
            case 'simbolo':
              acc.simbolo = objeto.val;
              break;
            case 'tipoParametro':
              acc.tipoParametro = objeto.val;
              break;
            case 'tipoPassagemParametro':
              acc.tipoPassagemParametro = objeto.val;
              break;
            default:
              break;
          }
          return acc;
        }, {});

        this.endpointsParametros = [endpointsParametros];

        if (this.relatorioCodigo) {
          this.gerarRelatorio()
        }
      } else {
        this.gerarRelatorio()
      }  
    },

    gerarGuid: function() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },

    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      RelatorioService.buscar(this.relatorioId, this.relatorioCodigo, this.sistema)
        .then((res) => {
          this.json = Buffer.from(
            res.data.arquivoRelatorio,
            'base64'
          ).toString();
          this.obterVariaveisRelatorio()
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.modelo_relatorio.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    abrirModal(id) {
      this.relatorioGerado = false
      this.exibirParametros = false
      this.exibirRelatorio = false
      this.optionSelecionado = null
      this.maxWidth = '500px'
      this.variaveisRelatorio = null
      this.optionParametros = []
      this.relatorioPossuiParametros = false
      this.tipoInput = null
      this.campoParametro = null
      this.tituloModal = this.$t('modulos.modelo_relatorio.filtro.titulo_modal_parametros_relatorio')
      this.relatorioId = id;
      this.endpointsParametros = null

      this.$nextTick(() => {
        this.$refs.relatorio.obterDataSourceInicial(this.relatorioId);
      })
      
      this.buscar();
      this.exibirParametros = true
      this.$refs.modal.abrirModal();
    },

    buscarRelatorioPorCodigo(codigo, id) {
      this.parametro = id
      this.exibirRelatorio = true
      this.relatorioCodigo = codigo;
     
      this.buscar();
    },

    gerarRelatorio() {
      if (this.relatorioId) {
        this.exibirParametros = false
        this.relatorioGerado = true
        this.exibirRelatorio = true
        this.tituloModal = this.$t('modulos.modelo_relatorio.filtro.titulo_modal_relatorio')
        this.maxWidth = '90%';
        this.$refs.relatorio.inicializarVisualizador(this.relatorioId, this.endpointsParametros);
      }

      if (this.relatorioCodigo) {
        this.$refs.relatorio.inicializarVisualizadorPorCodigo(this.relatorioCodigo, this.endpointsParametros, this.parametro);
      }
    }
  },
};
</script>
