<template>
  <div>
    <div 
      v-if="exibir"
      class="row"
    >
      <div
        v-if="tipoInput !== 'select'"
        class="col-12"
      >
        <input-text
          v-for="parametro in parametros"
          :key="parametro.id"
          v-model="optionSelecionado"
          :label="parametro.nome"
          :obrigatorio="parametro.obrigatorio"
        />
      </div>
      
      <input-select
        v-else
        v-model="optionSelecionado"
        class="col-12"
        :label="consulta"
        :options="optionParametros"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tipoInput: {
      type: String,
      default: 'text'
    },
    optionParametros: {
      type: Array,
      default: () => []
    },
    parametros: {
      type: Array,
      default: () => []
    },
    exibir: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    consulta: {
      type: String,
      default: null
    }
  },
  computed: {
    optionSelecionado: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
};
</script>
